
.btnLogin{
    position: fixed;
    top: 101px;
    z-index: 100;
    right: 28px;
    border: 1px solid rgba(0, 0, 0, 0.328);
    background-color: #75FC77;
    border-radius: 5px ;
    transition: all .5s;
}

.btnLogin:hover{
    background-color: black;
    color: white;
    transform: scale(1.2);

}


#seohd{
    padding: 9px;

}
