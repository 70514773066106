@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins',sans-serif;
  text-decoration: none;
}

footer .content{
  max-width: 1150px;
  margin: auto;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}


footer .content .topic{
  font-size: 22px;
  font-weight: 600;
  color: #f3ecec;
  margin-bottom: 16px;

}

footer .content .middle a{
  line-height: 32px;
}

footer .bottom{
  width: 100%;
  text-align: right;
  color: #f6f1f1;
  padding: 0 40px 5px 0;
}

.image img {
  width: 14rem;
}


@media (max-width:1100px) {
  footer .content .middle{
    padding-left: 50px;
  }
}
@media (max-width:950px){
  footer .content .box{
    width: 42%;
  }
  .content .right{
    margin-top: 40px;
  }
}
@media (max-width:560px){
  footer{
    position: relative;
  }
  footer .content .box{
    width: 100%;
    margin-top: 30px;
  }
  footer .content .middle{
    padding-left: 0;
  }
} 

#doagurulogo{
  margin-left: 1.5rem;
}
@media screen and (max-width: 768px) {
  #doagurulogo{
    margin-left: 4.5rem;
  }
}
@media screen and (max-width: 768px) {
  .bottom{
    margin-left: 1.4rem;
  }
}

@media screen and (min-width :768px) and (max-width: 1020px) {
  #doagurulogo{
    margin-top: 1rem;
    margin-left: 1.5rem;
  }
  #follow{
    margin-left: -3rem;
  }
  #logo{
    margin-left: -3rem;
  }  
}